import Vue from 'vue'
import Vuex from 'vuex'
import { userLogin, logOutApi, queryVerifyCode, verifyCodeApi, resetPasswordApi } from '@/api/index'

// import router from '../router/index'
// import { treeToList } from '@/utils/treeToList.js'
// import { constantRouterMap, asyncRouterMap } from '@/router/component.js'

Vue.use(Vuex)
const login = {
  namespaced: true,
  state: {
    // 菜单
    menus: [],
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    // 登录
    Login({ commit, dispatch }, payload) {
      return userLogin(payload)
    },
    // 退出登录
    logOut() {
      return logOutApi()
    },
    // 获取验证码
    async getVerifyCode({ commit, dispatch }, payload) {
      return await queryVerifyCode(payload)
    },
    // 校验验证码
    verifyCode({ commit, dispatch }, payload) {
      return verifyCodeApi(payload)
    },
    // 重置密码
    resetPassword({ commit, dispatch }, payload) {
      return resetPasswordApi(payload)
    },
  },
}
export default login
