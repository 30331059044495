import axios from 'axios'
import router from '@/router/index'
import Vue from 'vue'
let baseUrl = ''
if (process.env.NODE_ENV === 'local') {
  // 本地开发环境
  baseUrl = '/v1'
} else if (process.env.NODE_ENV === 'development') {
  // 开发环境
  baseUrl = 'https://dev.api.autobutler.botpy.com/v1'
} else if (process.env.NODE_ENV === 'production') {
  // 生产环境
  baseUrl = 'https://api.autobutler.botpy.com/v1'
} else {
  baseUrl = '/v1' // 本地环境
}
axios.defaults.withCredentials = true

const request = axios.create({
  // timeout: 5000,
  baseURL: baseUrl,
})
// 添加请求拦截器
request.interceptors.request.use(
  (config) => {
    if (config.headers && !config.headers['AutoButler-Sales-Page-Id']) {
      const uniqueId = localStorage.getItem('unique_id')
      config.headers['AutoButler-Sales-Page-Id'] = uniqueId
    }
    // 在发送请求之前做些什么
    return config
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error)
  },
)

// 添加响应拦截器
request.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return response
  },
  (error) => {
    const errorCode = error.response.status
    // const reqUrl = error.response.config.url
    if (errorCode === 401 || errorCode === 403) {
      localStorage.removeItem('userName')
      localStorage.removeItem('addRoutes')
      alertErrorInfo(error.response.data.errors[0].details[0].message)
      router.replace('/login')
    } else if (errorCode === 500) {
      alertErrorInfo('服务器错误，请联系管理员')
    } else {
      if (error.response.data.errors[0].details[0].code === 'account.user.await_handle_order') {
        return Promise.reject(error.response)
      }
      alertErrorInfo(error.response.data.errors[0].details[0].message)
    }

    // 对响应错误做点什么
    return Promise.reject(error.response)
  },
)
// 提示错误信息
function alertErrorInfo(msg) {
  const vm = new Vue()
  vm.$operationStatusMessage({
    type: 'error',
    text: msg,
  })
}

const get = request.get
const post = request.post
const Delete = request.delete
const put = request.put
const patch = request.patch

export { get, post, Delete, put, patch }
