export function formatParams(asyncRouterMap, menus) {
  // console.log(menus, 2)
  menus.forEach((item1) => {
    if (item1.children) {
      item1.children.forEach((item2) => {
        if (item2.children) {
          item2.children.forEach((item3) => {
            item1.children.push(item3)
          })
        }
      })
    }
  })
  const arr = JSON.parse(JSON.stringify(asyncRouterMap))
  arr[0].children = menus
  return filterAsyncRouter(asyncRouterMap, arr)
}
// 过滤异步路由表，返回符合用户角色权限的路由表
export function filterAsyncRouter(asyncRouterMap, menus) {
  // console.log(asyncRouterMap, menus, 22)
  const tempList = []
  // var tempList = []
  asyncRouterMap.forEach((itemA) => {
    // 获取两个参数是否相同
    const r = menus.find((itemB) => {
      // console.log(itemA, itemB, 28)
      return itemA.meta && itemA.meta.title
        ? itemB.name === itemA.meta.title || itemB.code === itemA.name
        : itemB.redirect === itemA.redirect
    })
    if (r) {
      // 如果相同则记录下来
      const obj = Object.assign({}, itemA)
      tempList.push(obj)
      // 递归调用函数
      if (itemA.children && itemA.children.length > 0 && r.children && r.children.length > 0) {
        obj.children = filterAsyncRouter(itemA.children, r.children)
      }
    }
  })
  tempList[0].redirect = tempList[0].children && tempList[0].children[0].path
  // console.log(tempList[0].redirect, 42, tempList[0].children && tempList[0].children[0].path)
  return tempList
}
// 获取request请求头携带参数、按钮权限
export function getOperationBtns(parentTitle, childTitle) {
  const menu = JSON.parse(localStorage.getItem('addRoutes'))
  let parentRoute = []
  let childRoute = []
  parentRoute = menu.filter(
    (item) => {
      return item.name === parentTitle
    },

    // if (item.children) {
    //   item.children.forEach((item) => {
    //     if (item.name === childTitle) {
    //       arr = item
    //     }
    //   })
    // }
    // }
  )[0]
  if (parentRoute && parentRoute.children) {
    childRoute = parentRoute.children.filter((child) => {
      return child.name === childTitle
    })[0]
  }
  localStorage.setItem('unique_id', childRoute.unique_id)
  return childRoute.children || []
}
