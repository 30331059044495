import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router/index'
import common from './common/index'
import login from './login/index'
import order from './order/index'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    supplier: [
      { name: '神州', value: 'shenzhou' },
      { name: '一嗨', value: '1hai' },
      { name: '长租车', value: 'long_lease' },
      { name: '加盟商', value: 'franchisee' },
    ],
    // 跟进状态
    followUpStatus: [
      { name: '已登记', value: 'Registered' },
      { name: '已联系', value: 'Contacted' },
      { name: '无人接听/拒接', value: 'NoAnswer' },
      { name: '调度跟进', value: 'Scheduling' },
      { name: '续单', value: 'RenewalOrder' },
      { name: '续租', value: 'RenewalLease' },
    ],
    // 菜单
    menus: [
      {
        path: '/',
        redirect: '/dataManagement',
        component: 'AppLayout',
        children: [
          {
            title: '数据管理',
            children: [{ title: '数据列表' }, { title: '数据详情' }, { title: '出行券详情' }],
          },
          {
            title: '用户管理',
            children: [
              { title: '用户列表' },
              { title: '添加用户' },
              { title: '编辑用户' },
              { title: '用户详情' },
            ],
          },
          {
            title: '角色管理',
            children: [
              { title: '角色列表' },
              { title: '添加角色' },
              { title: '编辑角色' },
              { title: '角色详情' },
            ],
          },
        ],
      },
    ],
    addRoutes: [],
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    // 登录
    Login({ dispatch }) {
      // return userLogin(payload).then((res) => {
      //   localStorage.setItem("refresh", res.data.refresh);
      localStorage.setItem('token', `AutoButler ${1213234546}`)
      //   localStorage.setItem("username", res.data.username);
      //   localStorage.setItem("name", res.data.name);
      //   localStorage.setItem("userId", res.data.id);
      //   dispatch("getRoutes");
      // });
      return dispatch('getRoutes')
    },
    // 获取路由信息
    getRoutes({ state }) {
      console.log(state, 83)
      // return () => {
      localStorage.setItem('addRoutes', JSON.stringify(state.menus))
      console.log(85)
      router.push('/')
      // };
    },
    // return queryRoutes()
    //   .then((res) => {
    //     const menus = res.data;
    //     localStorage.setItem("addRoutes", JSON.stringify(menus));
    //     router.push("/");
    //   })
    //   .catch(() => {
    //     localStorage.removeItem("token");
    //     router.push("/");
    //     // router.replace("/login");
    //   });
  },
  modules: { common, login, order },
})
