export const constantRouterMap = [
  {
    path: '/login',
    hidden: true,
    name: 'Login',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/login/loginIndex.vue'),
  },
  {
    path: '/forgetPassword',
    hidden: true,
    name: 'ForgetPassword',
    meta: {
      title: '密码验证',
    },
    component: () => import('@/views/login/loginIndex.vue'),
  },
  {
    path: '/resetPassword',
    hidden: true,
    name: 'ResetPassword',
    meta: {
      title: '重置密码',
    },
    component: () => import('@/views/login/loginIndex.vue'),
  },
  {
    path: '/404',
    hidden: true,
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import('@/views/404/notFound.vue'),
  },
]
export const asyncRouterMap = [
  {
    path: '/',
    redirect: '/dataManagement',
    hidden: false,
    component: () => import('@/components/AppLayout.vue'),
    children: [
      {
        path: '/dataManagement',
        name: 'DataManagement',
        redirect: '/dataManagement/list',
        meta: {
          title: '数据管理',
        },
        icon: true,
        hidden: false,
        component: () => import('@/views/dataManagement/dataManageIndex.vue'),
        children: [
          {
            path: '/dataManagement/list',
            name: 'DataManageList',
            meta: {
              title: '数据列表',
            },
            hidden: false,
            component: () => import('@/views/dataManagement/dataManage/dataManageList.vue'),
          },
          {
            path: '/dataManagement/detail',
            name: 'retrieve',
            meta: {
              title: '数据详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/dataManagement/dataManage/dataManageDetail.vue'),
          },
          {
            path: '/dataManagement/dataQueryList',
            name: 'DataQueryList',
            meta: {
              title: '数据查询',
            },
            hidden: false,
            component: () => import('@/views/dataManagement/dataQuery/dataQueryList.vue'),
          },
          {
            path: '/dataManagement/orderList',
            name: 'OrderList',
            meta: {
              title: '订单列表',
            },
            hidden: false,
            component: () => import('@/views/dataManagement/order/orderList.vue'),
          },
          {
            path: '/dataManagement/voucherCode',
            name: 'VoucherCode',
            meta: {
              title: '凭证码管理',
            },
            hidden: false,
            component: () => import('@/views/dataManagement/voucherCode/voucherCodeList.vue'),
          },
        ],
      },
      {
        path: '/commodityManagement',
        name: 'CommodityManagement',
        meta: {
          title: '商品管理',
        },
        icon: true,
        hidden: false,
        redirect: '/commodityManagement/list',
        component: () => import('@/views/commodityManagement/commodityIndex.vue'),
        children: [
          {
            path: '/commodityManagement/list',
            name: 'CommodityList',
            meta: {
              title: '订单列表',
            },
            hidden: false,
            component: () => import('@/views/commodityManagement/commodityList.vue'),
          },
        ],
      },
      {
        path: '/user',
        name: 'User',
        redirect: '/user/list',
        meta: {
          title: '用户管理',
        },
        hidden: false,
        icon: true,
        component: () => import('@/views/user/userIndex.vue'),
        children: [
          {
            path: '/user/list',
            name: 'List',
            meta: {
              title: '用户列表',
            },
            hidden: false,
            component: () => import('@/views/user/userList.vue'),
          },
          {
            path: '/user/addUser',
            name: 'create',
            meta: {
              title: '添加用户',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/user/userDetail.vue'),
          },
          {
            path: '/user/editUser',
            name: 'patch',
            meta: {
              title: '编辑用户',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/user/userDetail.vue'),
          },
          {
            path: '/user/detail',
            name: 'retrieve',
            meta: {
              title: '用户详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/user/userDetail.vue'),
          },
        ],
      },
      {
        path: '/role',
        name: 'Role',
        redirect: '/role/list',
        meta: {
          title: '角色管理',
          iconSrc: 'sidebar_icon_role_default@2x.png',
        },
        icon: true,
        hidden: false,
        component: () => import('@/views/role/roleIndex.vue'),
        children: [
          {
            path: '/role/list',
            name: 'List',
            meta: {
              title: '角色列表',
            },
            hidden: false,
            component: () => import('@/views/role/roleList.vue'),
          },
          {
            path: '/role/addRole',
            name: 'create',
            meta: {
              title: '添加角色',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/role/roleDetail.vue'),
          },
          {
            path: '/role/editRole',
            name: 'patch',
            meta: {
              title: '编辑角色',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/role/roleDetail.vue'),
          },
          {
            path: '/role/detail',
            name: 'retrieve',
            meta: {
              title: '角色详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/role/roleDetail.vue'),
          },
        ],
      },
    ],
  },
]
