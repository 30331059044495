<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
<style lang="scss">
page {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
<style scoped>
::v-deep .v-tooltip__content .menuable__content__active {
  border: dashed 1px #ccc;
  background: #ffffff;
  padding: 0;
}
</style>
