import { post, get, patch, Delete } from '@/utils/request'
function paramsFormat(params) {
  let str = ''
  for (const key in params) {
    if (params[key] instanceof Array) {
      params[key].forEach((item) => {
        str += `${key}=${item}&`
      })
    } else {
      str += `${key}=${encodeURIComponent(params[key])}&`
    }
  }
  str = str.slice(0, -1)
  return str
}
// 登录
export const userLogin = (params) => {
  return post('/sales/login', params)
}
// 退出登录
export const logOutApi = (params) => {
  return post('/sales/logout')
}
// 获取验证码
export const queryVerifyCode = (params) => {
  return post('/sales/verify-code', params)
}
// 校验验证码
export const verifyCodeApi = (params) => {
  return post('/sales/verify-code/check', params)
}
// 重置密码
export const resetPasswordApi = (params) => {
  console.log(params, 16)
  return post('/sales/users/reset-password', params)
}
// 渠道列表
export const queryChannels = (params) => {
  return get('/sales/channels')
}
// 数据详情
export const queryEntitlementDatasDetail = (params) => {
  return get(`/sales/entitlement_data/${params}`)
}
// 下载批量导入模板
export const queryBulkImportTemplate = (params) => {
  return get('/sales/entitlement_data/bulk_import_template', { params })
}
// 获取账号所属渠道
export const queryEntitlementChannels = (params) => {
  return get('/sales/entitlement_data/channels')
}
// 获取享权类型
export const queryServiceType = (params) => {
  return get('/sales/entitlement_data/service_type', { params })
}
// 获取服务产品类型
export const queryServiceProducts = (params) => {
  return get('/sales/entitlement_data/service_products', { params })
}
// 批量导入
export const bulkImportAPi = (params) => {
  return post('/sales/entitlement_data/bulk_import', params)
}
// 数据失效
export const invalidData = (params) => {
  return post('/sales/entitlement_data/invalidate', params)
}
// 敏感信息
export const querySensitiveInfo = (params) => {
  return get(`/sales/entitlement_data/${params.id}/sensitive_info`, { params })
}
// 用户列表
export const queryUserList = (params) => {
  return get(`/sales/users?${paramsFormat(params)}`)
}
//  获取用户详情
export const queryUsersDetail = (params) => {
  return get(`/sales/users/${params}`)
}
// 用户添加的角色
export const queryRolesData = (params) => {
  return get(`/sales/roles/simple-list`)
}
//  添加用户
export const createUserApi = (params) => {
  return post('/sales/users', params)
}
//  修改用户
export const editorUserApi = (params) => {
  return patch(`/sales/users/${params.id}`, params.data)
}
// 删除用户
export const deleteUserApi = (params) => {
  return Delete(`/sales/users/${params.id}`)
}
// 角色列表
export const queryRolesList = (params) => {
  return get(`/sales/roles`, { params })
}
//  角色详情
export const queryRolesDetail = (params) => {
  return get(`/sales/roles/${params}`)
}
// 角色权限菜单
export const queryMenus = (params) => {
  return get('/sales/menus')
}
//  添加角色
export const createRoleApi = (params) => {
  return post('/sales/roles', params)
}
//  修改角色
export const editorRoleApi = (params) => {
  return patch(`/sales/roles/${params.id}`, params.data)
}
// 删除角色
export const deleteRoleApi = (params) => {
  return Delete(`/sales/roles/${params.id}`)
}
// //  获取角色列表
// export const queryRolesList = (params) => {
//   return get('/sales/roles', { params })
// }
// 数据管理列表
export const queryDataManagementList = (params) => {
  return get(`/sales/entitlement_data?${paramsFormat(params)}`)
}
// 商品管理列表
export const queryRetailList = (params) => {
  return get(`/sales/retails/orders?${paramsFormat(params)}`)
}
// 退款
export const orderRefund = (params) => {
  return post(`/sales/retails/orders/${params.id}/refund`, params)
}
// 享权码查询列表
export const queryEntitlementList = (params) => {
  return get(`/sales/service-code?${paramsFormat(params)}`)
}
// 失效享权码
export const serviceCodeInvalidate = (params) => {
  return patch('/sales/service-code/invalidate', params)
}
// 代步车订单列表
export const queryCourtesyOrderList = (params) => {
  return get(`/sales/courtesy_car/orders?${paramsFormat(params)}`)
}
// 出行券订单列表
export const queryTripOrderList = (params) => {
  return get(`/sales/trip_coupon/orders?${paramsFormat(params)}`)
}
// 凭证码列表
export const queryVoucherCodeList = (params) => {
  return get(`/sales/generate_data/voucher_code?${paramsFormat(params)}`)
}
// 凭证码生成
export const createVoucherCode = (params) => {
  return post('/sales/generate_data/voucher_code', params)
}
// 凭证码下载
export const downloadVoucherCode = (params) => {
  return get(`/sales/generate_data/voucher_code/${params.id}`)
}
