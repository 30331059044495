<template>
  <div v-if="dialog" class="alerts-main">
    <div class="alerts-con">
      <img :src="iconSrc()" />
      <div class="alerts-text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
      text: '操作成功',
      type: 'success',
    }
  },
  mounted() {
    setTimeout(() => {
      this.dialog = false
    }, 2000)
  },
  methods: {
    iconSrc() {
      let src = ''
      switch (this.type) {
        case 'success':
          src = require('../assets/img/toast/icon_point_success.png')
          break
        case 'error':
          src = require('../assets/img/toast/icon_point_error.png')
          break
        case 'info':
          src = require('../assets/img/toast/icon_point_info.png')
          break
        case 'warning':
          src = require('../assets/img/toast/icon_point_warning.png')
          break
      }
      return src
    },
  },
}
</script>

<style lang="scss" scoped>
.alerts-main {
  width: 242px;
  padding: 12px 24px;
  background: #000000;
  opacity: 0.6;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 999;
  position: fixed;
  left: calc(50% - 121px);
  top: 40%;
  display: flex;
  justify-content: center;
  .alerts-con {
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
  .alerts-text {
    max-width: 168px;
    color: #ffffff;
    font-size: 14px;
  }
}
</style>
