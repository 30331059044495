const whitePath = [
  '/',
  '/dataManagement/list',
  '/user/list',
  '/role/list',
  '/dataManagement/dataQueryList',
  '/dataManagement/orderList',
  '/dataManagement/voucherCode',
]
export { whitePath }
