var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialog
    ? _c("div", { staticClass: "alerts-main" }, [
        _c("div", { staticClass: "alerts-con" }, [
          _c("img", { attrs: { src: _vm.iconSrc() } }),
          _c("div", { staticClass: "alerts-text" }, [_vm._v(_vm._s(_vm.text))]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }