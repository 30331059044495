import Vue from 'vue'
import Vuex from 'vuex'
import {
  queryChannels,
  queryEntitlementChannels,
  queryServiceType,
  queryServiceProducts,
} from '@/api/index'

Vue.use(Vuex)
const common = {
  namespaced: true,
  state: {
    // 所有渠道
    channels: [],
    // 账号所属渠道
    entitlementChannels: [],
    // 享权类型
    serviceType: [],
    // 获取服务产品
    serviceProducts: [],
    // 享权类型
    codeTypes: [
      { name: '车架号', id: 'vin' },
      { name: '凭证码', id: 'voucher_code' },
      { name: '服务编号', id: 'service_number' },
      { name: '车牌号', id: 'plate_number' },
    ],
    // 使用情况
    is_used: [
      { name: '已使用', id: 'true' },
      { name: '未使用', id: 'false' },
    ],
    // 服务产品
    serviceProduct: [
      { name: '是', id: 'true' },
      { name: '否', id: 'false' },
    ],
    // 允许查询全部数据和部分数据
    dataScope: [
      { name: '仅导入的数据', id: 'partial' },
      { name: '归属渠道的全部数据', id: 'all' },
    ],
    // 享权码状态
    status: [
      { name: '生效中', id: 'active' },
      { name: '待生效', id: 'will_take_effect' },
      { name: '已过期', id: 'expired' },
      { name: '失效', id: 'invalid' },
    ],
    // 服务类型
    product_filter: [
      { name: '代步车', id: 'courtesy_car' },
      { name: '租车卡', id: 'car_rental_card' },
    ],
    // 订单状态
    status_filter: [
      { name: '待支付', id: '0' },
      { name: '已支付', id: '1' },
      { name: '已取消', id: '2' },
      { name: '已退款', id: '3' },
    ],
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    // 渠道列表
    getChannels({ commit, dispatch }) {
      return queryChannels().then((res) => {
        commit({ type: 'setState', key: 'channels', values: res.data })
      })
    },
    // 获取账号所属渠道
    getEntitlementChannels({ commit, dispatch }) {
      return queryEntitlementChannels().then((res) => {
        commit({ type: 'setState', key: 'entitlementChannels', values: res.data.data })
      })
    },
    // 获取享权类型
    getServiceType({ commit, dispatch }, payload) {
      return queryServiceType(payload).then((res) => {
        commit({ type: 'setState', key: 'serviceType', values: res.data.data })
      })
    },

    // 获取服务产品
    getServiceProducts({ commit, dispatch }, payload) {
      return queryServiceProducts(payload).then((res) => {
        commit({ type: 'setState', key: 'serviceProducts', values: res.data.data })
      })
    },
  },
}
export default common
