// 组件挂载到vue上
import Message from '../components/OperationStatusMessage'
export default {
  install(Vue) {
    Vue.prototype.$operationStatusMessage = function (options) {
      const MessageConstructor = Vue.extend(Message)
      const el = new MessageConstructor({
        data() {
          return {
            ...options,
          }
        },
      }).$mount()
      document.body.appendChild(el.$el)
    }
  },
}
