import Vue from 'vue'
import Vuex from 'vuex'
import {
  queryBulkImportTemplate,
  bulkImportAPi,
  queryEntitlementDatasDetail,
  querySensitiveInfo,
  queryUsersDetail,
  queryRolesData,
  createUserApi,
  editorUserApi,
  queryRolesList,
  queryRolesDetail,
  queryMenus,
  createRoleApi,
  editorRoleApi,
  queryCourtesyOrderList,
  queryTripOrderList,
} from '@/api/index'

// import router from '../router/index'
// import { treeToList } from '@/utils/treeToList.js'
// import { constantRouterMap, asyncRouterMap } from '@/router/component.js'

Vue.use(Vuex)
const order = {
  namespaced: true,
  state: {
    // 菜单
    menus: [],
    dataManagementSearch: {}, // 数据列表搜索条件
    orderListSearch: {}, // 订单列表搜索条件
    dataQuerySearch: {}, // 查询享权搜索条件
    userSearch: {}, // 用户搜索条件
    roleSearch: {}, // 用户搜索条件
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    // 下载批量导入模板
    getBulkImportTemplate({ commit, dispatch }, payload) {
      return queryBulkImportTemplate(payload)
    },
    // 批量导入
    bulkImport({ commit, dispatch }, payload) {
      return bulkImportAPi(payload)
    },
    // 数据详情
    getEntitlementDatasDetail({ commit, dispatch }, payload) {
      return queryEntitlementDatasDetail(payload)
    },
    // 敏感信息
    getSensitiveInfo({ commit, dispatch }, payload) {
      return querySensitiveInfo(payload)
    },
    //  获取用户详情
    getUsersDetail({ commit, dispatch }, payload) {
      return queryUsersDetail(payload)
    },
    // 用户添加的角色
    getRolesData({ commit, dispatch }, payload) {
      return queryRolesData(payload)
    },
    //  添加用户
    createUser({ commit, dispatch }, payload) {
      return createUserApi(payload)
    },
    //  修改用户
    editorUser({ commit, dispatch }, payload) {
      return editorUserApi(payload)
    },
    // 角色列表
    getRolesList({ commit, dispatch }, payload) {
      return queryRolesList(payload)
    },
    // 角色详情
    getRolesDetail({ commit, dispatch }, payload) {
      return queryRolesDetail(payload)
    },
    // 角色权限菜单
    getMenus({ commit, dispatch }) {
      return queryMenus()
    },
    // 创建角色
    createRole({ commit, dispatch }, payload) {
      return createRoleApi(payload)
    },
    // 修改角色
    editorRole({ commit, dispatch }, payload) {
      return editorRoleApi(payload)
    },
    getCourtesyOrderList({ commit, dispatch }, payload) {
      return queryCourtesyOrderList(payload)
    },
    getTripOrderList({ commit, dispatch }, payload) {
      return queryTripOrderList(payload)
    },
    // 数据列表搜索条件更改
    changeDataManagementSearch({ commit }, payload) {
      commit({ type: 'setState', key: 'dataManagementSearch', values: payload })
    },
    // 订单列表搜索条件更改
    changeOrderListSearch({ commit }, payload) {
      commit({ type: 'setState', key: 'orderListSearch', values: payload })
    },
    // 享权查询列表
    changeDataQuerySearch({ commit }, payload) {
      commit({ type: 'setState', key: 'dataQuerySearch', values: payload })
    },
    // 用户列表搜索条件更改
    changeUserSearch({ commit }, payload) {
      commit({ type: 'setState', key: 'userSearch', values: payload })
    },
    // 角色列表搜索条件更改
    changeRoleSearch({ commit }, payload) {
      commit({ type: 'setState', key: 'roleSearch', values: payload })
    },
  },
}
export default order
