import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { whitePath } from './utils/globalVariable'
import Message from './utils/message' // 操作后状态提示

Vue.config.productionTip = false
Vue.prototype.whitePath = whitePath
Vue.use(Message)
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
